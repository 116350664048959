.App {
  text-align: center;
  background: #eeeeee;
  min-height: 90vh;
  padding-bottom: 25px;
}

p {
  font-family: 'OpenSans';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

table {
  border-collapse: collapse;
}

.bold {
  font-weight: bold;
}

.mainHeader {
  color: #264d66;
  font-family: "Audiowide";
  text-align: center;
}

.secondaryHeader {
  color: #264d66;
  font-family: "Audiowide";
}

.alink {
  color: #51ade5;
  font-family: "Audiowide";
  text-decoration: none;
}

table.gamelog th, table.gamelog  td {
  border: 1px solid gray;
  color: #3a3b3c;
  text-transform:capitalize;
}

.MuiPaper-rounded {
  border: 2px solid #51ade5;
}

.MuiTableCell-footer {
  border-bottom: none !important;
}

.MuiTableRow-root .MuiTableCell-root td {
  padding: 0px !important;
}

.MuiTableCell-root {
  padding: 5px !important;
}

.MuiTableCell-paddingCheckbox {
  width: auto !important;
}

/* .MuiIconButton-root {
  padding: 0px !important;
} */

.MuiTab-root {
  min-width: 72px !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #51ade5 !important;
}

.PrivateTabIndicator-colorPrimary-91 {
  background-color: #264d66 !important;
}

.MuiBox-root {
  padding: 2px !important;
}

.footer {
  background-image: url('./assets/images/footer.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  align-items: center;
  display: flex;
  justify-content: center;
  position: static;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #51ade5;
  color: white;
  text-align: center;
  padding: 0px;
  height: 110px;
  border-top: 4px solid #356e91;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #51ade5;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.type_dropdown {
  z-index: 111;
}