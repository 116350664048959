.MuiAppBar-root {
    z-index: 999 !important;
  }

/* .MuiAccordion-root {
  background-color: #51ade5 !important;
} */

.MuiCollapse-wrapper {
  /* color: white !important; */
  display: block !important;
}

.MuiAccordionDetails-root {
  /* color: white !important; */
  display: grid !important;
  padding: 8px 16px 16px;
}

/* .blur {
  backdrop-filter: blur(3px);
  opacity: 85%;
} */


.animatedBG {
  backdrop-filter: blur(3px);
  background: linear-gradient(to right, 
    rgb(53, 110, 145, 0.85),
    rgb(81, 173, 229, 0.85),
    rgb(35, 213, 171, 0.85));
  background-size: 200% 200%;
  /* animation: gradient 15s ease infinite; */
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

  .bm-item {
    color: white !important;
}

  .bm-burger-bars {
    background: white !important;
}

.MuiTypography-colorPrimary {
  color: white !important;
  font-weight: bold !important;
  margin: 10;
}

.MuiLink-underlineHover:hover {
  text-decoration: wavy !important;
}

.custom-tooltip {
  background-color: #51ace5b4;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px grey;
}
  
  .navBarContainer {
      height: 64px;
  }
  
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 16px;
    top: 16px;
  }
   
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
   
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
   
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
   
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
   
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
   
  /* General sidebar styles */
  .bm-menu {
    overflow: hidden !important;
    background: #264d66e8;
    backdrop-filter: blur(3px);
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
   
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
   
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
   
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
   
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }